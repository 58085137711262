@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Montserrat;
    src: local(Montserrat),
        url(./assets/fonts/Montserrat.ttf);
  }

* {
    font-family: Montserrat;
    /*scroll-behavior: smooth;*/
    overflow-anchor: none;
}

.nav-item {
    position: relative;
}

.nav-item.active {
    font-weight: 500;
    color: #4f46e5;
}

.nav-item::after {
    position: absolute;
    top: 24px;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: #4f46e5;
    content: '';
    transition: 0.3s ease-in;
}

.nav-item.active::after {
    width: 100%;
    transition: 0.3s ease-in;
}

.cls-1 {
	opacity: 1 !important;
	fill: #5a56ea !important;
}

.cta-wrapper {
    position: relative;
    z-index: 1;
    padding: 80px 80px;
}

.cta-wrapper::after {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: url(../public/map.png) no-repeat right;
    background-size: contain;
    z-index: -1;
}

.cta-img {
    position: absolute;
    right: 0px;
    bottom: -150px;
    overflow: hidden;
}

@media (max-width: 640px) {
    .cta-img {
        bottom: -100px;
    }  
}

.st-one {
    font-size: 24px;
    padding: 20px 15px;
    z-index: 1;
    position: relative;
    font-weight: 700;
}

.cta-text {
    font-size: 56px;
    line-height: 120%;
    letter-spacing: -0.03em;
}

.fill-text {
    line-height: 1;
    letter-spacing: -0.03em;
    margin: 0 auto;
    text-align: center;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.pattern-bg:after {
    position: absolute;
    right: 150px;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: url(../public/shape-5.png) no-repeat;
    background-size: 100% 100%;
    z-index: -1;
}

.sub-title {
    font-size: 44px;
    line-height: 60px
}

.plan {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 15px;
}

.currency {
    font-size: 44px;
    font-weight: bold;
    display: inline-block;
}

.price {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
}

.pricing-description {
    height: 130px;
}

.pricing-list {
    margin-bottom: 36px;
}

.pricing-list li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: var(--heading);
    font-size: 18px;
    font-weight: 500;
}

.pricing-list li:before {
    content: "✓";
    font-weight: 400;
    flex: 0 0 25px;
    max-width: 25px;
    height: 25px;
    font-size: 14px;
    border-radius: 50%;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity));;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    margin-right: 15px;
}

.ribbon {
    display: inline-flex;
    background-color: #ffcd3e;
    position: absolute;
    top: 37px;
    right: -10px;
    transform: rotate(90deg);
    color: black;
    padding: 0 10px;
    line-height: 30px;
    font-weight: 700;
}

.uncheck {
    opacity: 0.35;
}

.accordion {
    overflow-anchor: none;
}

.ac {
    margin-top: 10px;
    border: none;
    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;
    transition: 0.3s;
}

.ac-header {
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 115%;
}

.ac .ac-trigger {
    font-weight: 500;
    line-height: 175%;
    text-align: left;
    width: 100%;
    padding: 15px 40px 15px 20px;
    display: block;
    cursor: pointer;
    background-color: transparent;
    transition: color 0.25s ease;
    position: relative;
    text-decoration: none;
    margin: 0;
    border: 0;
    font-weight: bold;
}

.ac-trigger::after {
    content: "+";
    text-align: center;
    width: 15px;
    transform: translate(0, -50%);
    position: absolute;
    right: 10px;
    top: 50%;
}

.ac .ac-panel {
    overflow: hidden;
    transition-property: height, visibility;
    transition-timing-function: ease;
}

.ac.js-enabled .ac-panel {
   height: 0px;
}

.ac.is-active .ac-panel {
    height: 305px;
}

.ac .ac-panel .ac-text {
    font-size: 18px;
    font-weight: 400;
    color: black;
    padding: 15px 40px 20px 20px;
    margin: 0;
    white-space: pre-wrap;
}

.text-wrapper h2 {
    line-height: 115%;
    margin-bottom: 32px;
}

.cta-text>.fill-text {
    -webkit-text-stroke-color: white;
}

.text-wrapper p {
    margin-bottom: 40px;
}

.information-style-one .icon span {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #4338ca;
}

.info {
    color: black;
    font-weight: bold;
    font-size: 22px;
}

.form_control {
    margin-bottom: 30px;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    padding: 0 30px;
    font-weight: 700;
    color: black;
    border: 1px solid rgba(88, 86, 233, 0.2);
}


.form_group {    
    padding-right: 15px;
}

.contact-form form textarea.form_control {
    padding-top: 15px;
    height: 140px;
    resize: vertical;
}

.shape {
    transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); 
    transform-style: preserve-3d; 
    backface-visibility: hidden; 
    pointer-events: none;
}

.shape.shape-icon-two {
    right: -50px;
    bottom: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #4338ca;
    -webkit-filter: blur(100px);
    filter: blur(100px);
}

.img-holder {
    position: relative;
    z-index: 1;
    margin-right: -105px;
    padding-left: 30%;
    text-align: right;
}

.img-holder img {
    border-radius: 185px;
}

.footer-top {
    border-bottom: 1px solid rgba(89, 86, 233, 0.2);
}

.footer-img {
    width: 244px;
    height: 100%;
}

.footer-top .text-wrapper {
    padding-left: 170px;
    border-left: 2px solid rgba(89, 86, 233, 0.2);
}

.footer-copyright {
    padding: 25px 0;
    border-top: 1px solid rgba(89, 86, 233, 0.2);
}

.social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.social-nav {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.social-nav li {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


@media (max-width: 767px) {
    .footer-top .text-wrapper {
        padding-left: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-left: none;
        border-top: 2px solid rgba(89, 86, 233, 0.2);
    }

    .footer-logo {
        padding-left: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .cta-wrapper {
        padding: 80px 20px;
    }

    .sub-title {
        font-size: 24px;
        line-height: 36px
    }

    .cta-text {
        font-size: 38px;
    }
}

@media (hover: hover) {
    
    .nav-item:hover {
        color: #4f46e5;
    }


    .nav-item:hover::after {
        width: 100%;
        transition: 0.3s ease-in;
    }
}

.custom-slide-in {
    animation: slideIn 0.5s forwards;
  }
  
  .custom-slide-out {
    animation: slideOut 0.5s forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(120%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(120%);
    }
  }